<template>
	<div class="taskCenter">
		<div v-loading="loading" style="width:100%;height:100%"></div>
		<div class="Progress">
			<ul class="flex1 ul-Cord">
				<li v-for="(item,index) in LevelList" :key="index">
					<div class="level-card" v-if="(item.levelName == InfoList.level.levelName) && (index+1 < LevelList.length)">
						<div class="levelName-bg">
							<img src="../../assets/PC/profile/titleBg.png" alt="">
							<h1 class="level-title">{{InfoList.level.levelName}}</h1>
						</div>
						<div class="levelNums flex3">
							<div class="icon-text0">距离下一等级，还有
								<b v-if="index+1 < LevelList.length"> {{ LevelList[index+1].start - InfoList.integral}}
								</b>
								<b v-else> {{ LevelList[index].end - InfoList.integral}} </b>积分
							</div>
							<div class="icon-jian"><img src="@/assets/PC/profile/sub.png" /></div>
						</div>
						<div class="san0 san"></div>
						<div class="san1 san"></div>
					</div>
				</li>
			</ul>
			<ul class="flex1" style="margin-top: 15px">
				<li v-for="(item,index) in LevelList" :key="index">
					<div class="Progress-icon flex1">
						<div class="icon1 mr" :class=" item.start <= InfoList.integral ? 'icon11' : ''"></div>
						<div class="icon2"
							:class="((item.end < InfoList.integral) ? 'icon21' : (item.end > InfoList.integral && item.start < InfoList.integral) ? 'icon22' : '')">
						</div>
					</div>
					<div class="levelName tc" :class="(item.end < InfoList.integral) ? 'myCollectName' : (item.levelName == InfoList.level.levelName ? 'active3' : '')">
						{{ item.levelName }}
					</div>
				</li>
			</ul>
		</div>
		<div class="hr1"></div>
		<div class="taskCenter-nav">
			<ul class="flex">
				<li v-for="(item,index) in navList" :key="index" @click="navActive0(index)" class="mr flex0 cur" :class="index == navActive ? 'active2' : ''">{{item}}</li>
			</ul>
		</div>
		<div class="taskList">
			<ul>
				<li v-for="(item,index) in taskList.data" :key="index" class="taskList-item flex2">
					<div class="hidden taskList-item-name" :title="item.name">{{ item.name }}</div>
					<div class="taskList-item-num flex5">
						<div class="mr"> {{ item.num }} </div>
						<img v-if="navActive == 1" src="@/assets/PC/profile/gold.png" alt="" width="20" height="20">
						<img v-else src="@/assets/PC/profile/1.png" alt="" width="20" height="20">
						<div class="multiple" v-if="item.multiple > 1"> ({{ item.multiple}}倍)</div>
					</div>
					<div class="hidden taskList-item-time tr">{{ item.time }}</div>
				</li>
			</ul>
		</div>
		<div v-if="taskList.data.length > 0 && taskList.recordCount > 3" class="page tr">
			<el-pagination @current-change="handleCurrentChange" :total="taskList.recordCount"
				:page-size="taskList.pageSize" :current-page="taskList.pageIndex" background layout="pager, next"
				next-text="下一页" hide-on-single-page>
			</el-pagination>
		</div>
		<div v-if="IsloadList" class="loadList flex0">
			<div class="loadList0" @click="loadlist">加载更多</div>
		</div>
		<div v-if="clientWidth < 750 && !IsloadList && taskList.data.length > 0" class="tc notDetail">
			<div>
				没有更多内容了~ 
				<span class="violet" @click="scrollTop">返回顶部</span>
			</div>
		</div>
	</div>
</template>

<script>
	import * as profile from "@/api/profile.js";
	import { dataState } from "@/api/all.js";
  import { GetWxShares } from "@/utils/common";

	export default {
		name: "taskCenter",
		data() {
			return {
				loading: true, // !加载动画
				navList: ['积分', '金币'], // !Tab切换
				navActive: 0, // !当前选中模块
				taskList: {
					data: [], // !数据list
					recordCount: 0, // !总条数
					pageIndex: 1, // !当前页数
					pageSize: 10, // !每页条数
				},
				LevelList: [], // !等级分级
				InfoList: {}, // !用户分级
				IsloadList: false, // !移动端点击加载更多
			}
		},
		mounted() {
      const share = {
      title: '个人中心 | 剑盟社区',
      desc: ''
    }
    GetWxShares(share);
			profile.GetLevelList().then(res => {
				profile.GetUserInfo().then(res1 => {
					this.LevelList = res.data;
					this.InfoList = res1.data;
					this.loading = false;
				})
			})
			const data = {
				'type': 0
			}
			profile.GetRecord(data).then(res => {
				this.taskList = res;
				if (this.taskList.recordCount > 10) {
					this.IsloadList = true
				} else {
					this.IsloadList = false
				}
			})

			// 数据统计
			const data1 ={
				"visitType": 1,
				"gid": sessionStorage.getItem('gid') ? sessionStorage.getItem('gid') : '',
				"url": window.location.href,
				"pageUrl": "taskCenter",
				"relationId": ""
			}
			dataState(data1).then(res => {
				sessionStorage.setItem('gid',res.data);
			})
		},
		methods: {
			// !顶部切换
			navActive0(index) {
				this.loading = true;
				this.navActive = index;
				const data = {
					'type': index
				}
				profile.GetRecord(data).then(res => {
					this.loading = false;
					this.taskList = res;
					if (this.taskList.recordCount > 10) {
						this.IsloadList = true
					} else {
						this.IsloadList = false
					}
				})
			},
			// !分页
			handleCurrentChange(currentPage) {
				const data = {
					page: currentPage,
					limit: 10,
					type: this.navActive
				}
				this.taskList = {
					data: [],
					recordCount: 0,
					pageIndex: 1,
					pageSize: 10,
				};
				profile.GetRecord(data).then(res => {
					this.taskList = res;
				})
			},
			// !点击加载更多
			loadlist() {
				this.loading = true;
				this.taskList.pageIndex++;
				const data = {
					'type': this.navActive,
					page: this.taskList.pageIndex
				}
				profile.GetRecord(data).then(res => {
					if (this.taskList.recordCount > this.taskList.pageIndex * 10) {
						this.IsloadList = true
					} else {
						this.IsloadList = false
					}
					this.taskList.data = this.taskList.data.concat(res.data);
					this.loading = false;
				})
			},
			// !返回顶部
			scrollTop() {
				let top = document.documentElement.scrollTop || document.body.scrollTop;
				// 实现滚动效果 
				const timeTop = setInterval(() => {
					document.body.scrollTop = document.documentElement.scrollTop = top -= 100;
					if (top <= 0) {
						clearInterval(timeTop);
					}
				}, 10);
			}
		},
		computed: {
			clientWidth () {
				return this.$store.state.clientWidth
			}
		}
	}
</script>

<style lang="scss">
	$mobil_base: 750;

	@function vw($true) {
		@return ($true / $mobil_base) * 100vw;
	}

	.taskCenter {
		width: 100%;padding: 40px;
		.Progress {height: auto;width: 100%;margin: 0 auto;}
		.Progress ul {width: 100%;}
		.Progress ul li {position: relative;width: 20%;}
		.Progress ul li:last-child .icon11 {background: initial !important;border-left: 16px solid #6b18a8 !important}

		.Progress .level-card {width: 100%;border: 1px #ccc solid;}
		.Progress .levelName-bg {position: relative;}
		.Progress .levelName-bg img{margin: -7px 0 0 -1px;}
		.Progress .level-title {position: absolute;left: 10px;top: 0;color: #fff;font-weight: bold;font-size: 16px;}
		.Progress .levelNums {padding: 20px 15px;}
		.icon-text0 {margin-right: 5px;}
		.Progress .icon-jian img{width: 35px;height: 35px;}
		.san{width:0;height:0;border-right: 8px solid transparent;border-left: 8px solid transparent;border-top: 8px solid #ccc;position: absolute;left: 50%;bottom: -7px;transform: translateX(-50%);}
		.san1{bottom: -6px;border-top: 8px solid #fff}

		.Progress-icon{position: absolute;left: calc(50% - 4px);}
		.Progress ul li:last-child .Progress-icon .icon1 {width: 0;height: 0;border-left-color: #ccc;border-radius: 0;background: initial;border-top: 8px solid transparent;border-left: 16px solid #ccc;border-bottom: 8px solid transparent;margin: -3px 0 0 -5px;}
		.Progress ul li:last-child .Progress-icon .icon2 {display: none;}
		.icon1 {width: 10px;height: 10px;background: #ccc;border-radius: 50%;}
		.icon2 {width: 160px;height: 2px;background: #ccc;}
		.icon11 {background: #6b18a8 !important;}
		.icon21 {background: #6b18a8 !important;}
		.icon22 {background: linear-gradient(to right, #6b18a8, #ccc) !important;}
		.Progress .levelName {font-size: 20px;color: #ccc;margin-top: 20px;}
		.hr1 {width: 100%;margin: 40px 0;border-top: 2px #666 dashed;}

		.taskCenter-nav {margin-bottom: 40px;}
		.taskCenter-nav ul li {width: 82px;height: 32px;border-radius: 3px;background: #F2F5F7;font-size: 16px;}
		.taskList-item {width: 100%;height: 51px;background: #F6F8FA; margin-bottom: 21px; border-radius: 8px;padding: 0 40px;}
		.taskList-item-name {width: calc((100% - 70px) / 2); max-width: calc((100% - 70px) / 2); font-weight: bold;}
		.taskList-item-num {min-width: 70px; font-weight: bold; position: relative; white-space: nowrap}
		.multiple {position: absolute; right: -40px; font-weight: normal;}
		.taskList-item-time {width: calc((100% - 70px) / 2);}

	}

	@media (max-width: 750px) {
		.taskCenter {
			padding: vw(40);
			.ul-Cord {margin-left: vw(-40);}
			.Progress .level-card {width: 180%;}
			.Progress .level-title {left: vw(10);top: vw(-6);}
			.Progress .level-title{font-size: vw(24);}
			.Progress .levelNums {padding: vw(5)}
			.levelName-bg img {width: vw(150);}
			.Progress .icon-jian img{width: vw(40);height: vw(40);}
			.icon-text0 {font-size: vw(24);}
			.icon1{width: vw(15);height: vw(15);}
			.icon2 {width: vw(80);}
			.san {left: 85%;transform: translateX(-50%);}
			.Progress .levelName {font-size: vw(24)}
			.Progress ul li:last-child .Progress-icon .icon1{border-top: vw(12) solid transparent;border-left: vw(24) solid #ccc;border-bottom: vw(12) solid transparent;margin-left: vw(-16);}

			.hr1{margin: vw(40) 0;}
			.taskList-item {height: vw(67);padding: 0 vw(26);}
			.taskList-item-name {width: calc((100% - 14.93333vw) / 2);max-width: calc((100% - 14.93333vw) / 2);font-size: vw(24);}
			.taskList-item-num {min-width: vw(112);}
			.taskList-item-num div {font-size: vw(20);}
			.taskList-item-num img {width: vw(30);height: vw(30);}
			.multiple {right: vw(-60);}
			.taskList-item-time {width: calc((100% - 14.93333vw) / 2);font-size: vw(23);}
		}
	}
</style>
